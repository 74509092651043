import { UseQueryOptions } from 'react-query';
import { coreHttpClient } from '../../helpers/http';

export const GetCUInfo = () => ({
  queryKey: ['CU-info'],
  queryFn: () => coreHttpClient.get('/info'),
});

export const GetDataPrepareInfo = () => ({
  queryKey: ['DataPrepare-info'],
  queryFn: () =>
    coreHttpClient.get('/api/infoparameter/getdatapreparerlastversion'),
});

export const GetDataPrepareDownloadStatus = (os: 'win' | 'mac') => ({
  queryKey: ['DataPrepare-download-status'],
  queryFn: () =>
    coreHttpClient.get(
      `/api/infoparameter/getdatapreparerdownloadstatus?os=${os}`
    ),
});

export const GetSubmissions = ({
  isEnabled,
}: {
  isEnabled: boolean;
}): UseQueryOptions => ({
  queryKey: ['CU-submissions'],
  queryFn: () => coreHttpClient.get('/api/submissions'),
  refetchInterval: 5000,
  enabled: isEnabled,
});

export const GetProfileInfo = () => ({
  queryKey: ['CU-profile-info'],
  queryFn: () => coreHttpClient.get('/api/profile'),
});

export const GetPaywallInfo = () => ({
  queryKey: ['CU-paywall-info'],
  queryFn: () => coreHttpClient.get('/api/paywall'),
});
