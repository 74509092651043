import { UseQueryOptions } from 'react-query';
import { coreHttpClient } from '../../../helpers/http';
import { Comparison } from '../../containers/PersonaProfilePage/Comparison';

export const GetPersonaProfileAge = ({
  getDataBy = 'monte-carlo',
}: {
  getDataBy?: 'monte-carlo' | 'range';
}): UseQueryOptions => ({
  queryKey: [`Persona-age-${getDataBy}`],
  queryFn: () => coreHttpClient.get(`/api/personaprofile/${getDataBy}/age`),
});

export const GetPersonaProfileRace = ({
  getDataBy = 'monte-carlo',
}: {
  getDataBy?: 'monte-carlo' | 'range';
}): UseQueryOptions => ({
  queryKey: [`Persona-race-${getDataBy}`],
  queryFn: () => coreHttpClient.get(`/api/personaprofile/${getDataBy}/race`),
});

export const GetPersonaProfileSex = ({
  getDataBy = 'monte-carlo',
}: {
  getDataBy?: 'monte-carlo' | 'range';
}): UseQueryOptions => ({
  queryKey: [`Persona-sex-${getDataBy}`],
  queryFn: () => coreHttpClient.get(`/api/personaprofile/${getDataBy}/sex`),
});

export const GetPersonaProfileEducation = ({
  getDataBy = 'monte-carlo',
}: {
  getDataBy?: 'monte-carlo' | 'range';
}): UseQueryOptions => ({
  queryKey: [`Persona-education-${getDataBy}`],
  queryFn: () =>
    coreHttpClient.get(`/api/personaprofile/${getDataBy}/education`),
});

export const GetPersonaProfileLanguage = ({
  getDataBy = 'monte-carlo',
}: {
  getDataBy?: 'monte-carlo' | 'range';
}): UseQueryOptions => ({
  queryKey: [`Persona-language-${getDataBy}`],
  queryFn: () =>
    coreHttpClient.get(`/api/personaprofile/${getDataBy}/language`),
});

export const GetPersonaProfileSocialTab = ({
  comparison,
  isEnabled,
}: {
  comparison: string;
  isEnabled: boolean;
}): UseQueryOptions => ({
  queryKey: [`Persona-social-tab-${Comparison[+comparison]}`],
  queryFn: () =>
    coreHttpClient.get(
      `/api/personaprofile/social?geographicalScope=${comparison}`
    ),
  enabled: isEnabled,
});

export const GetPersonaProfileFamiliesTabs = ({
  comparison,
  isEnabled,
}: {
  comparison: string;
  isEnabled: boolean;
}): UseQueryOptions => ({
  queryKey: [`Persona-families-tab-${Comparison[+comparison]}`],
  queryFn: () =>
    coreHttpClient.get(
      `/api/personaprofile/families?geographicalScope=${comparison}`
    ),
  enabled: isEnabled,
});

export const GetPersonaProfileAgeTabs = ({
  comparison,
  isEnabled,
}: {
  comparison: string;
  isEnabled: boolean;
}): UseQueryOptions => ({
  queryKey: [`Persona-age-tab-${Comparison[+comparison]}`],
  queryFn: () =>
    coreHttpClient.get(
      `/api/personaprofile/age?geographicalScope=${comparison}`
    ),
  enabled: isEnabled,
});

export const GetPersonaProfileHousingTab = ({
  comparison,
  isEnabled,
}: {
  comparison: string;
  isEnabled: boolean;
}): UseQueryOptions => ({
  queryKey: [`Persona-housing-tab-${Comparison[+comparison]}`],
  queryFn: () =>
    coreHttpClient.get(
      `/api/personaprofile/housing?geographicalScope=${comparison}`
    ),
  enabled: isEnabled,
});

export const GetPersonaProfileEconomicTab = ({
  comparison,
  isEnabled,
}: {
  comparison: string;
  isEnabled: boolean;
}): UseQueryOptions => ({
  queryKey: [`Persona-economic-tab-${Comparison[+comparison]}`],
  queryFn: () =>
    coreHttpClient.get(
      `/api/personaprofile/economic?geographicalScope=${comparison}`
    ),
  enabled: isEnabled,
});

export const GetPersonaProfileDemographicTab = ({
  comparison,
  isEnabled,
}: {
  comparison: string;
  isEnabled: boolean;
}): UseQueryOptions => ({
  queryKey: [`Persona-demographic-tab-${Comparison[+comparison]}`],
  queryFn: () =>
    coreHttpClient.get(
      `/api/personaprofile/demographic?geographicalScope=${comparison}`
    ),
  enabled: isEnabled,
});
