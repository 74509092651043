import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import humps from 'humps';
import AuthService from './client'; // Importing AuthService

const auth = new AuthService();

type HttpClientOptions = {
  baseURL: string;
  withCredentials?: boolean;
};

export const createHttpClient = ({
  baseURL,
  withCredentials = true,
}: HttpClientOptions): AxiosInstance => {
  const coreHttpClient = axios.create({
    baseURL,
    withCredentials,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
    validateStatus: (status) => status >= 200 && status < 302,
  });

  // Request Interceptor
  coreHttpClient.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      const token = window.localStorage.getItem('token');
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // Response Interceptor
  coreHttpClient.interceptors.response.use(
    async (response: AxiosResponse) => {
      if (response.data.err) {
        if (response.data.status === 401) {
          window.location.href = '/';
        }
        return Promise.reject(response);
      }
      return humps.camelizeKeys(response.data) as AxiosResponse;
    },
    async (error) => {
      if (error.response?.status === 401) {
        // Using AuthService for login handling
        auth.login({ redirectUrl: window.location.href });
        localStorage.removeItem('token');
        localStorage.removeItem('idToken');
        localStorage.removeItem('isProcessingModalClose');
      }
      return Promise.reject(error.response);
    }
  );

  return coreHttpClient;
};
