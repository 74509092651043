/* eslint-disable no-inner-declarations */
/* eslint-disable func-names */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, FC, useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { findByDisplayValue } from '@testing-library/react';
import { FixedSizeList as List } from 'react-window';
import useDebounce from './useDebounce';
import s from './style/Autocomplete.module.scss';
import { addTags, resetTags } from '../../Slice/impactReportSlice';
import type { RootState } from '../../../../store/store';
import { coreHttpClient } from '../../../../../helpers/http';

type TypeAutocomplete = {
  value: string;
  setInput: (value: string) => void;
  setIsLoading: (value: boolean) => void;
};
const Autocomplete: FC<TypeAutocomplete> = ({
  value,
  setInput,
  setIsLoading,
}) => {
  const [filtered, setFiltered] = useState<any[]>(['']);
  const [isShow, setIsShow] = useState(false);
  const debouncedSearch = useDebounce<string>(value, 600);

  const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
  const dispatch = useDispatch();
  const items = useAppSelector((state) => state.impactReport);

  useEffect(() => {
    setFiltered([]);
    setIsShow(false);
    if (debouncedSearch.length > 1) {
      setIsLoading(true);
      coreHttpClient
        .get(
          `api/impactreporting/locationitemautocomplete?searchTerm=${debouncedSearch}&subType=${items.tempLocation.locationType}`
        )
        .then((autosuggestions: any) =>
          setFiltered(
            autosuggestions?.result.filter(
              (o1: any) =>
                // eslint-disable-next-line eqeqeq
                !items.tagsData.some((o2: any) => o1.entityId == o2.entityId)
            )
          )
        )
        .then(() => {
          setIsLoading(false);
          setIsShow(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch]);

  //  useEffect(() => {
  //    setIsShow(false);
  //    setInput('');
  //    dispatch(resetTags());
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  //  }, [items.tempLocation]);

  const onClick = (suggestions: any) => {
    dispatch(
      addTags({
        tagId: suggestions.entityId,
        tagName: suggestions.value,
      })
    );
    setFiltered([]);
    setIsShow(false);
    setInput('');
  };

  const renderAutocomplete = () => {
    if (isShow && findByDisplayValue.length > 2) {
      if (filtered.length > 0) {
        const Row = ({
          index,
          style,
        }: {
          index: number;
          style: React.CSSProperties;
        }) => (
          <li
            className={`${s.autocomplete__suggestions__item}`}
            style={style} // important for virtualization to apply correct positioning
            onClick={() => onClick(filtered[index])}
          >
            {filtered[index].value}
          </li>
        );
        return (
          <List
            className={s.autocomplete__suggestions}
            height={200} // Adjust the height based on the available space
            itemCount={filtered.length}
            itemSize={35} // Adjust item size based on your design
            width="100%"
          >
            {Row}
          </List>
        );
      }
      return (
        <ul className={s.autocomplete__suggestions}>
          <li className={s.autocomplete__noSuggestions}>
            <em>Not found</em>
          </li>
        </ul>
      );
    }
    return <></>;
  };

  return <div>{renderAutocomplete()}</div>;
};

export default Autocomplete;
