import { UseQueryOptions } from 'react-query';
import { coreHttpClient } from '../../../helpers/http';

export const mapLoanRows = ({
  selectedRows,
}: {
  selectedRows: {
    mapRows: {
      id: number;
      typeId: number;
      typeCodeDesc: string;
    };
  }[];
}) =>
  coreHttpClient.post(
    '/api/datamanagement/submiteditedloanmaprows',
    selectedRows
  );

export const GetLoanTabData = ({
  filterBy,
  take = 10,
  page,
  isRefetch,
  sortBy,
  sortDirection,
}: {
  filterBy: 0 | 1 | 2;
  take?: number;
  page?: number;
  isRefetch?: boolean;
  sortBy: string;
  sortDirection: 0 | 1 | 2;
}): UseQueryOptions => ({
  queryKey: [
    'Loan-table-filterBy-',
    take,
    filterBy,
    page,
    isRefetch,
    sortBy,
    sortDirection,
  ],
  queryFn: () =>
    coreHttpClient.get(
      `/api/datamanagement/loanmappingdata?filter=${filterBy}${
        page
          ? `&page=${page}&take=${take}&orderByProperty=${sortBy}&orderBy=${sortDirection}`
          : ''
      }`
    ),
});

export const GetIsLoanTabData = ({
  isRefetch,
}: {
  isRefetch: boolean;
}): UseQueryOptions => ({
  queryKey: [`Loan-table-${isRefetch ? '-refetched' : ''}`],
  queryFn: () => coreHttpClient.get('/api/datamanagement/loanmappingdata'),
  refetchInterval: isRefetch ? 10000 : false,
  refetchOnWindowFocus: false,
  cacheTime: 0,
  staleTime: 0,
});

export const GetLoanTypesDropdown = (): UseQueryOptions => ({
  queryKey: ['Loan-table-dropdown'],
  queryFn: () => coreHttpClient.get('/api/datamanagement/loantypes'),
});
